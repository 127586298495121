<template>
  <div class="content">
    <van-sticky>
      <van-search
        class="search-bar"
        show-action
        shape="round"
        v-model="keywords"
        @search="handleSearch"
        placeholder="查线路、站点"
      >
        <template #left>
          <van-icon name="arrow-left" @click="goBack" />
        </template>
        <template #action>
          <div class="action" @click.stop.prevent="handleSearch">搜索</div>
        </template>
      </van-search>
    </van-sticky>
    <div class="stations">
      <div class="station-item" v-for="item in stationList" :key="item.staCode" @click="handleSelceted(item)">
        <p class="name">
          <i class="iconfont icon-gongjiaozhanpai"></i>
          {{ item.name }}
        </p>
        <p class="address">
          {{ item.address }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import api from "@/api";
import { IObject } from '@/types/inerface';
import { AxiosRequestConfig } from "axios";
import { Toast } from 'vant';
import { defineComponent, reactive, toRefs } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter()
    const route = useRoute()
    const flag = route.query.which
    let state = reactive({
      keywords: "",
      city: store.state.city.city,
      stationList: [],
    });
    const handleSearch = () => {
      Toast.loading({
        duration: 300,
        message: '加载中...',
        forbidClick: true,
      });
      api.bus.transSta({
        cityname: state.city,
        keywords: state.keywords,
      }).then((res: AxiosRequestConfig["data"]) => {
        // console.log(res)
        state.stationList = res.data;
      });
    };
    const handleSelceted = (station: IObject) => {
      store.commit(`${flag}Init`, station)
      router.go(-1)
    }
    const goBack = () => {
      router.go(-1)
    }
    return {
      handleSelceted,
      goBack,
      handleSearch,
      ...toRefs(state),
    };
  },
});
</script>

<style lang="less" scoped>
.action {
  width: 100px;
  text-align: center;
  font-size: 32px;
}

.stations {
  margin: 16px 0;
  .station-item {
    padding: 24px;
    border-bottom: 2px solid #ddd;
    .iconfont {
      font-weight: bold;
      color: #1989fa;
    }
    p {
      margin: 0;
    }
    .name {
      margin-bottom: 16px;
      font-size: 30px;
      color: #333;
      i {
        font-size: 36px;
        padding: 0 8px;
      }
    }
    .address {
      margin-left: 16px;
      font-size: 24px;
      color: rgb(128, 128, 128);
    }
  }
}
</style>
