
import api from "@/api";
import { IObject } from '@/types/inerface';
import { AxiosRequestConfig } from "axios";
import { Toast } from 'vant';
import { defineComponent, reactive, toRefs } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter()
    const route = useRoute()
    const flag = route.query.which
    let state = reactive({
      keywords: "",
      city: store.state.city.city,
      stationList: [],
    });
    const handleSearch = () => {
      Toast.loading({
        duration: 300,
        message: '加载中...',
        forbidClick: true,
      });
      api.bus.transSta({
        cityname: state.city,
        keywords: state.keywords,
      }).then((res: AxiosRequestConfig["data"]) => {
        // console.log(res)
        state.stationList = res.data;
      });
    };
    const handleSelceted = (station: IObject) => {
      store.commit(`${flag}Init`, station)
      router.go(-1)
    }
    const goBack = () => {
      router.go(-1)
    }
    return {
      handleSelceted,
      goBack,
      handleSearch,
      ...toRefs(state),
    };
  },
});
